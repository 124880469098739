<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: ['chartData', 'options'],
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData(val) {
      this.renderChart(val, this.options)
    },
    options(val) {
      this.renderChart(this.chartData, val)
    },
  },
}
</script>
