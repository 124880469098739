<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Performance do Gate">
          <v-row class="mb-5">
            <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
              <Filtro
                v-model="params"
                :filters="filters"
                @search="getRelatorio"
              />
            </v-col>
          </v-row>
          <div v-for="gate in Object.values(data)" v-bind:key="gate.public_id">
            <v-row class="text-center">
              <v-col cols="12">
                <span class="display-1">
                  {{ gate.business_name }}
                </span>
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col cols="10" sm="5" offset="1">
                <Agendamentos :data="gate.meses" />
              </v-col>
              <v-col cols="10" sm="5" offset="1" offset-sm="0">
                <Servicos :data="gate.meses" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" sm="10" offset="1">
                <Detalhes :data="gate" />
                <div style="margin: 20px">
                  <v-btn
                    color="info"
                    class="w-100 my-1"
                    @click="getDownload(gate)"
                    :disabled="!canDownload"
                  >
                    <v-icon class="mx-2" left dark> mdi-download </v-icon>
                    Baixar Planilha</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-if="Object.keys(data).length == 0">
            <v-row>
              <v-col
                cols="10"
                sm="8"
                md="6"
                lg="4"
                offset="1"
                offset-sm="2"
                offset-md="3"
                offset-lg="4"
              >
                <v-alert
                  border="left"
                  dense
                  dismissible
                  outlined
                  text
                  type="info"
                >
                  Nenhum informação computada.
                </v-alert>
              </v-col>
            </v-row>
          </div>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Filtro from '@/components/relatorios/gates/Filtro'
import Agendamentos from '@/components/relatorios/gates/Agendamentos'
import Servicos from '@/components/relatorios/gates/Servicos'
import Detalhes from '@/components/relatorios/gates/Detalhes'
import RelatorioApi from '@/services/relatorios'
import { getUrl, downloadUrl } from '@/utils/index'

export default {
  provide() {
    return {
      tipo: 'Gates',
    }
  },
  data() {
    return {
      page: {
        title: 'Performance dos Gates',
      },
      breadcrumbs: [
        {
          text: 'Relatórios',
          disabled: true,
        },
        {
          text: 'Performance',
          disabled: false,
        },
        {
          text: 'Gate',
          disabled: false,
        },
      ],
      headers: [],
      data: {},
      params: {},
      loading: false,
      filters: {
        gates: [],
        anos: [],
      },
    }
  },
  components: { Filtro, Agendamentos, Servicos, Detalhes },
  async created() {
    await this.getOptions()
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    canDownload() {
      return Object.keys(this.params).length > 0
    },
  },
  methods: {
    ...mapActions('template', ['errorMessage']),
    load() {
      this.loading = true
    },
    loaded() {
      this.loading = false
    },
    async getOptions() {
      const data = await RelatorioApi.getRelatorioGatesOptions()
      this.filters.gates = data.filter?.gate
      this.filters.anos = data.filter?.ano
    },
    async getRelatorio() {
      let { gate, ...params } = this.params
      if (gate) {
        gate = gate.join(',')
      }
      this.load()
      try {
        const data = await RelatorioApi.getRelatorioGates({
          ...params,
          gate,
        })
        this.data = data
      } catch (error) {
        if (error.response) {
          if (error.response.data.detail) {
            this.errorMessage(error.response.data.detail)
            return
          }
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      } finally {
        this.loaded()
      }
    },
    async download(item) {
      await this.getDownload(item)
    },
    async getDownload(item) {
      if (!this.canDownload) return
      if (!item) return
      this.load()
      let data = []
      try {
        const path = `api/v1/relatorios/gates/${item.public_id}/download/`
        let { ...params } = this.params
        delete params.gate
        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Não foi possível baixar a planilha. Tente novamente mais tarde.'
        )
      } finally {
        this.loaded()
      }
      return data
    },
  },
}
</script>

<style></style>
