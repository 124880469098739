<template>
  <div>
    <v-row class="text-center">
      <v-col cols="6">
        <v-btn
          color="primary"
          class="my-1"
          title="Remover filtros"
          small
          block
          @click="clear"
        >
          <v-icon class="mx-3">mdi-filter-remove</v-icon>
          <span class="d-none d-sm-flex">Limpar Filtros</span>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          color="primary"
          class="my-1"
          title="Pesquisar"
          small
          block
          @click="search"
        >
          <v-icon class="mx-3">mdi-database-search</v-icon>
          <span class="d-none d-sm-flex">Pesquisar</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8">
        <v-autocomplete
          v-model="value.gate"
          label="Gate"
          :items="filters.gates"
          :item-text="
            (item) =>
              item.business_name +
              ' - ' +
              `${item.cnpj != null ? item.cnpj : item.cpf}`
          "
          item-value="public_id"
          hint="Selecione os Gates."
          multiple
          persistent-hint
          prepend-icon="mdi-boom-gate"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="value.ano"
          label="Ano"
          :items="filters.anos"
          hint="Selecione o Ano."
          persistent-hint
          prepend-icon="mdi-calendar"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    clear() {
      this.value.ano = null
      this.value.gate = null
    },
    search() {
      this.$emit('search')
    },
  },
}
</script>
