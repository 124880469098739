<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      disable-sort
      dense
      hideDefaultFooter
    >
      <template v-slot:[`item.valor`]="{ item }">
        {{ toReal(item.valor) || toReal(0) }}
      </template>
      <template
        v-for="header in headers.filter((header) => header.servico)"
        v-slot:[`item.${header.value}`]="{ item }"
      >
        <div v-bind:key="header.value" class="d-flex flex-column">
          <span class="">
            {{ toReal(header.value in item ? item[header.value].valor : 0) }}
          </span>
          <span class="">
            N° Cms
            {{ header.value in item ? item[header.value].quantidade : 0 }}
          </span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { toReal } from '@/mixins/convertion.js'
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [toReal],
  data() {
    return {}
  },
  computed: {
    headers() {
      if (Object.keys(this.data).length > 0) {
        let _headers = [{ text: 'Mês', value: 'mes' }]
        this.data.headers.forEach((item) =>
          _headers.push({ text: item, value: item, servico: true })
        )
        _headers.push({ text: 'Total', value: 'total' })
        _headers.push({ text: 'Valor', value: 'valor' })
        return _headers
      } else {
        return []
      }
    },
    items() {
      if (Object.keys(this.data).length > 0) {
        let _items = []
        _items = Object.values(this.data.meses).map((item) => {
          return {
            mes: item.mes,
            total: item.servicos.detalhes.total,
            valor: item.servicos.detalhes.valor,
            ...item.servicos.detalhes.items,
          }
        })
        console.log(this.data)
        console.log(this.headers)
        console.log(_items)
        return _items
      }
      return []
    },
  },
  methods: {},
}
</script>
