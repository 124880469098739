<template>
  <div>
    <BarChart :chartData="agendamentos" :options="options" />
  </div>
</template>

<script>
import BarChart from '@/components/Charts/__BarChart'
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BarChart,
  },
  data() {
    return {
      options: {
        responsive: true,
      },
    }
  },
  computed: {
    agendamentos() {
      if (Object.keys(this.data).length > 0) {
        const chartData = {
          labels: [],
          datasets: [],
        }
        chartData.labels = Object.values(this.data).map((item) => item.mes)
        chartData.datasets = [
          {
            label: 'Agendados',
            backgroundColor: '#57b757',
            data: Object.values(this.data).map(
              (item) => item.agendamentos.agendados
            ),
          },
          {
            label: 'Cancelados',
            backgroundColor: '#f87979',
            data: Object.values(this.data).map(
              (item) => item.agendamentos.cancelados
            ),
          },
        ]
        return chartData
      }
      return {}
    },
  },
  methods: {},
}
</script>
